import React, {useState } from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { MdDelete } from "react-icons/md";
import { RiMailAddFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { useConfigureContext } from "../Context/ConfigureContext"
import {findInvalidEmail} from "../utils/HelperFunction"
import useToast from "../Hooks/Toast"

const EmailNotificationForm = ({ currentEmail }) => {
    const notify = useToast();
    const {
        getEmail,
        setGetEmail,
        setFinalSaveEnable
    } = useConfigureContext();

    const [emails, setEmails] = useState([{ email: '' }]);
    const [editEnable, setEditEnable] = useState(true);
    const [addMultipleEmails, setAddMultipleEmails] = useState(false);

    const handleInputChange = (index, event) => {
        const newEmails = [...emails];
        newEmails[index][event.target.name] = event.target.value;
        setEmails(newEmails);
    };

    const handleAddEmail = () => {
        setEmails([...emails, { email: '' }]);
    };

    const handleRemoveEmail = (index) => {
        const newEmails = emails.filter((_, emailIndex) => emailIndex !== index);
        setEmails(newEmails);
    };

    const handleSubmit = () => {
        // event.preventDefault();
        const finalEmails = emails.map(emailObj => emailObj.email);
        const emailCheck = findInvalidEmail(finalEmails);

        if (emailCheck.isValid) {
            setGetEmail(finalEmails)
            setEditEnable(false)
            setFinalSaveEnable(true)
        }
        else {
            emailCheck.email?.length ?
                notify(`${emailCheck.email} Is not a valid mail`, 'error') :
                notify(`please enter valid mail`, 'error');
        }
        // Add your form submission logic here
    };

    const handleOperatorChange = (event) => {
        if (event.target.value === 'true') {
            setFinalSaveEnable(false)
        }
        else {
            setGetEmail([])
            setFinalSaveEnable(true)
        }
        setAddMultipleEmails(event.target.value);
    };

    return (
        <div style={{ marginTop: '60px' }} className="summary shadow bg-white m-0 flex-column align-center justify-space additional-buttons">
            {editEnable ? <>
                <div className='mail-group'>
                    <h3 className='thirdh3'>Get notification on</h3>
                    <RadioGroup
                        row
                        aria-label="operator"
                        name="operator"
                        value={addMultipleEmails}
                        onChange={handleOperatorChange}
                    >
                        <FormControlLabel
                            value={false}
                            control={<Radio sx={{ color: addMultipleEmails ? '#30a953' : 'initial' }} />}
                            label="Use current email"
                            className={addMultipleEmails ? "or" : ""}
                        />
                        <FormControlLabel
                            value={true}
                            control={<Radio sx={{ color: !addMultipleEmails ? '#30a953' : 'initial' }} />}
                            label="Add multiple emails"
                            className={!addMultipleEmails ? "and" : ""}
                        />
                    </RadioGroup>
                </div>
                {addMultipleEmails === 'true' &&
                    <>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
                            <form onSubmit={() => handleSubmit}>
                                <>
                                    {emails.map((email, index) => (
                                        <div key={index} style={{ padding: '6px', display: 'flex' }}>
                                            <input
                                                style={{ borderRadius: '4px', padding: '6px', marginRight: '10px' }}
                                                type="email"
                                                name="email"
                                                value={email.email}
                                                onChange={(event) => handleInputChange(index, event)}
                                                required
                                            />
                                            {emails.length > 1 && (
                                                <div style={{ padding: '10px' }}>
                                                    <MdDelete
                                                        className={'pointer-cell'}
                                                        size={20}
                                                        color='#4d4b4b'
                                                        onClick={() => {
                                                            handleRemoveEmail(index)
                                                        }} />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                                <div style={{ padding: '10px' }}>
                                    <RiMailAddFill
                                        className={'pointer-cell'}
                                        size={20}
                                        color='#4d4b4b'
                                        onClick={() => {
                                            handleAddEmail()
                                        }} />
                                </div>
                            </form>
                            <button
                                onClick={() => handleSubmit()}
                                className='email-submit' type="submit" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                Submit
                            </button>
                        </div>
                    </>
                }
            </> :
                <>
                    <h3 className='thirdh3'>Get notification on</h3>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
                        <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {getEmail.map((email, index) => (
                                <li key={index} style={{ padding: '10px', }}>
                                    {email}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <FaEdit
                        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
                        className={'pointer-cell'}
                        size={20}
                        color='#4d4b4b'
                        onClick={() => {
                            setEditEnable(true)
                            setFinalSaveEnable(false)
                        }} />
                    </div>
                </>}
        </div>
    );
};

export default EmailNotificationForm;
