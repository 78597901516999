// FilterGroup.jsx
import React, { useEffect, useState } from "react";
import { useConfigureContext } from "../Context/ConfigureContext"
import {
  DimesionFilterOptions,
  MetricFilterOptions,
  DimensionOperator,
  MetricOperator,
} from "../utils/constants";

const Group = ({
  filterGroup,
  index,
  onDelete,
  setIsActiveFilterGroup,
  setIsActiveDimension,
}) => {

  const {
    setAppOptionGroup,
    selectedDimension,
    setSelectedDimension,
    selectedDimensionOperator,
    setSelectedDimensionOperator,
    dimesionFilterValue,
    dimensionValue,
    setDimensionValue,
    setDimesionFilterValue,
    metricFilterValue,
    setMetricFilterValue,
    isActiveFilterGroup,
    excludeValue,
    setExcludeValue,
    matchCase,
    setMatchCase,
  } = useConfigureContext();

  const functionToFetchLeble = (key, array) => {
    const obj = array?.find((item) => {
      return item?.key === key
    })
    return obj?.label;
  }

  const onChangeDimesionValue = (e, index) => {
    setDimesionFilterValue(e.target.value);
    setDimensionValue((prevActives) => ({
      ...prevActives,
      [index]: e.target.value,
    }));
  };
  const [ApplyButton, setApplyButton] = useState(true);

  useEffect(() => {
    if (filterGroup?.data) {
      if (filterGroup.type === "dimension") {
        // setting the value of expression --
        setDimesionFilterValue(filterGroup?.data?.expressions?.[0]);
        setDimensionValue((prevActives) => ({
          ...prevActives,
          [filterGroup?.id]: filterGroup?.data?.expressions?.[0]
        }));

        // handle exclude and match....
        handleSetExcludeValue(filterGroup?.id, filterGroup?.data?.caseSensitive)
        handleSetMatchCase(filterGroup?.id, filterGroup?.data?.not)

        handleChangeDimensionValue(filterGroup?.id, filterGroup?.data?.dimensionName)
      }
      else {
        setMetricFilterValue(filterGroup?.data?.comparisonValue);
        setDimensionValue((prevActives) => ({
          ...prevActives,
          [filterGroup?.id]: filterGroup?.data?.comparisonValue
        }));

        // handle match only in matrix....
        handleSetExcludeValue(filterGroup?.id, filterGroup?.data?.not)

        handleChangeMetricValue(filterGroup?.id, filterGroup?.data?.metricName)
      }

      // selected operator in both cases ....
      handleChangeOperator(filterGroup?.id, filterGroup?.data?.operator)
    }
  }, [])

  const ApplyClick = () => {
    setAppOptionGroup(true);
    setApplyButton(!ApplyButton);
  };

  const onChangeMetricValue = (e, index) => {
    setMetricFilterValue(e.target.value);
    setDimensionValue((prevActives) => ({
      ...prevActives,
      [index]: e.target.value,
    }));
  };

  function handleChangeMetricValue(index, value) {
    setSelectedDimension((prevActives) => ({
      ...prevActives,
      [index]: value,
    }));
  }

  function handleChangeDimensionValue(index, value) {
    setSelectedDimension((prevActives) => ({
      ...prevActives,
      [index]: value,
    }));
  }

  function handleChangeOperator(index, value) {
    setSelectedDimensionOperator((prevActives) => ({
      ...prevActives,
      [index]: value,
    }));
  }

  function handleSetExcludeValue(index, value) {
    setExcludeValue((prevActives) => ({
      ...prevActives,
      [index]: value,
    }));
  }

  function handleSetMatchCase(index, value) {
    setMatchCase((prevActives) => ({
      ...prevActives,
      [index]: value,
    }));
  }

  return (
    <div
      key={index}
      id={`${index}-filter-group-drop`}
      className="p-5 shadow bg-white triggers-dropdown"
    >
      <h3>
        {filterGroup.type === "dimension" ? "Dimension" : "Metric"} Filter Group
      </h3>
      <div className="border border-[#9C9C9C] p-5 mt-5">
        <h4>
          {filterGroup.type === "dimension" ? "Dimension" : "Metric"} Filter
        </h4>
        {filterGroup.type === "dimension" ? (
          <>
            {ApplyButton ? <>
              <div className="flex m-0 justify-space">
                {/* Dropdown for selecting dimension */}
                <div className=" m-0">
                  <select
                    className="border border-black w-[15dvw] p-2 rounded-md"
                    onChange={(e) => {
                      handleChangeDimensionValue(filterGroup.id, e.target.value);
                      setIsActiveDimension(false);
                    }}
                    value={selectedDimension[filterGroup.id]}
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                  >
                    <option disabled={true} selected={true}>
                      Select Dimension
                    </option>
                    {DimesionFilterOptions.map((option, index) => (
                      <option key={index} value={option.key} className="">
                        {option !== "Select Dimension" && option.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* dropdwown for selecting dimension operator */}
                <div className=" equals m-0">
                  <select
                    className="border border-black w-[15dvw] p-2 rounded-md"
                    onChange={(e) => {
                      handleChangeOperator(filterGroup.id, e.target.value);
                      setIsActiveFilterGroup(!isActiveFilterGroup);
                    }}
                    value={selectedDimensionOperator[filterGroup.id]}
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      // display: isActiveFilterGroup ? "block" : "none",
                    }}
                  >
                    <option disabled={true} selected={true}>
                      Select Operator
                    </option>
                    {DimensionOperator.map((option, index) => (
                      <option key={index} value={option.key} className="text-sm">
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Input for dimension value */}
                <div className="">
                  <input
                    value={dimensionValue?.[filterGroup.id]}
                    placeholder={
                      filterGroup.type === "dimension"
                        ? "Dimension Value"
                        : "Metric Value"
                    }
                    className="border border-black w-[25dvw] p-3 rounded-md"
                    onChange={(e) => onChangeDimesionValue(e, filterGroup.id)}
                  />
                </div>
              </div>
              <div className="flex my-10 gap-7">
                <div className=" flex align-center">
                  <input
                    checked={excludeValue?.[filterGroup.id]}
                    type="checkbox"
                    onChange={() => {
                      handleSetExcludeValue(filterGroup.id, !excludeValue?.[filterGroup.id])
                    }}
                  />
                  <label>&nbsp;Exclude Value(s)</label>
                </div>
                <div className=" flex align-center">
                  <input
                    checked={matchCase?.[filterGroup.id]}
                    type="checkbox"
                    onChange={() => handleSetMatchCase(filterGroup.id, !matchCase?.[filterGroup.id])}
                  />
                  <label>&nbsp;Match Case</label>
                </div>
              </div>
              </> :
              <>
                <div className="container">
                  <div className="item">
                    <label className="label">Selected Dimension</label>
                    <div className="content">{functionToFetchLeble(selectedDimension[filterGroup.id], DimesionFilterOptions)}</div>
                  </div>

                  <div className="item">
                    <label className="label">Selected Operator</label>
                    <div className="content">{functionToFetchLeble(selectedDimensionOperator?.[filterGroup.id], DimensionOperator)}</div>
                  </div>

                  <div className="item">
                    <label className="label">Dimension Value</label>
                    <div className="content">{dimensionValue?.[filterGroup.id]}</div>
                  </div>

                  <div className="item">
                    <label className="label">Exclude Value(s)</label>
                    <div className="content">{excludeValue?.[filterGroup.id] ? 'TRUE' : 'FALSE'}</div>
                  </div>
                </div>
              </>}
          </>
        ) : (
          <>
            {ApplyButton ?
              <>
                <div className="flex m-0 justify-space">
                  {/* Dropdown for selecting dimension */}
                  <div className="dropdown m-0">
                    <select
                      className="dropdown-content dropdown-btn"
                      onChange={(e) => {
                        handleChangeMetricValue(filterGroup.id, e.target.value);
                        setIsActiveDimension(false);
                      }}
                      value={selectedDimension[filterGroup.id]}
                      style={{
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        // display: isActiveDimension ? "block" : "none",
                      }}
                    >
                      <option disabled={true} selected={true}>
                        Select Metric
                      </option>
                      {MetricFilterOptions.map((option, index) => (
                        <>
                          <option key={index} value={option.key} className="item">
                            {option !== "Select Metric" && option.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  {/* Dropdown for selecting dimension operator */}

                  <div className="dropdown equals m-0">
                    <select
                      className="dropdown-content dropdown-btn"
                      onChange={(e) => {
                        handleChangeOperator(filterGroup.id, e.target.value);
                        setIsActiveFilterGroup(!isActiveFilterGroup);
                      }}
                      value={selectedDimensionOperator[filterGroup.id]}
                      style={{
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        // display: isActiveFilterGroup ? "block" : "none",
                      }}
                    >
                      <option disabled={true} selected={true}>
                        Select Operator
                      </option>
                      {MetricOperator.map((option, index) => (
                        <option key={index} value={option.key} className="item">
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Input for metric value */}
                  <div className="">
                    <input
                      // value={metricFilterValue}
                      value={dimensionValue?.[filterGroup.id]}
                      placeholder={
                        filterGroup.type === "dimension"
                          ? "Dimension Value"
                          : "Metric Value"
                      }
                      className="dimension-input"
                      onChange={(e) => onChangeMetricValue(e, filterGroup.id)}
                    />
                  </div>
                </div>
                <div className="flex mt-35">
                  <div className="checkbox-item flex align-center">
                    <input
                      checked={excludeValue?.[filterGroup.id]}
                      type="checkbox"
                      onChange={() => handleSetExcludeValue(filterGroup.id, !excludeValue?.[filterGroup.id])}
                    />
                    <label>Exclude Value(s)</label>
                  </div>
                </div>
              </>
              : <>
                <div className="container">
                  <div className="item">
                    <label className="label">Selected Metric</label>
                    <div className="content">{functionToFetchLeble(selectedDimension[filterGroup.id], MetricFilterOptions)}</div>
                  </div>

                  <div className="item">
                    <label className="label">Selected Operator</label>
                    <div className="content">{functionToFetchLeble(selectedDimensionOperator?.[filterGroup.id], MetricOperator)}</div>
                  </div>

                  <div className="item">
                    <label className="label">Metric Value</label>
                    <div className="content">{dimensionValue?.[filterGroup.id]}</div>
                  </div>

                  <div className="item">
                    <label className="label">Exclude Value(s)</label>
                    <div className="content">{excludeValue?.[filterGroup.id] ? 'TRUE' : 'FALSE'}</div>
                  </div>
                </div>
              </>}
          </>
        )}
        <div className="flex align-center justify-end gap-5">
          <button
            className="font-normal px-5 py-2 rounded-[7px] hover:text-white hover:bg-primaryColor border border-primaryColor text-primaryColor bg-white"
            onClick={() => {
              onDelete(index);
            }}
          >
            Delete
          </button>
          <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white" onClick={() => ApplyClick()}>
            {ApplyButton ? 'Apply' : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Group;
