export function formatCurrency(amount) {
  if (amount === null || amount === undefined) {
    return '0.00';
  }

  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export function formatNumberWithCommas(number) {
  const parts = number.toString().split('.');
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return integerPart + decimalPart;
}

// export function formatNumberWithCommas(number) {
//     const parts = number.toString().split('.');
//     let integerPart = parts[0];
//     const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

//     // Add commas for Indian numbering format
//     const lastThreeDigits = integerPart.slice(-3);
//     const otherDigits = integerPart.slice(0, -3);

//     if (otherDigits !== '') {
//       integerPart = otherDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ',' + lastThreeDigits;
//     }

//     return integerPart + decimalPart;
//   };