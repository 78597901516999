import React from "react";
import "../../assets/style/common.scss";
import LoginIcon from "../../assets/icons/google.svg";
import ShowPassword from "../../assets/icons/showPassword.svg";
import HidePassword from "../../assets/icons/hidePassword.svg";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import LoginController from "./login-controller";
import Header from "../template/Header";
import Footer from "../template/Footer";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const { responseGoogle, loading } = LoginController();
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <>
      {loading ? (
        <div className="none">
          <Header />
          <div className="loading flex align-center justify-center">
            <div className="loading-Overlay"> </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="container">
          <div className="login">
            <div className="login-box">
              <h1>Sign Up</h1>
              <input className="input-1" type="text" placeholder="Email" />
              <div className="password">
                <input
                  className="input-1"
                  placeholder="Password"
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                />
                <span
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? (
                    <img src={ShowPassword} alt="Log in" />
                  ) : (
                    <img src={HidePassword} alt="Log in" />
                  )}
                </span>
              </div>
              <button className="button bg-primaryColor mt-45">Sign Up</button>
              <p className="already-user">
                Already a member?
                <a href="/login">Log in</a>
              </p>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}>
                <GoogleLogin
                  render={(renderProps) => (
                    <button
                      className="log-in-btn button flex justify-center rounded-[10px]"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img src={LoginIcon} alt="Log in" />
                      Log In with Google
                    </button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy="single_host_origin"
                />
              </GoogleOAuthProvider>
              <ToastContainer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
