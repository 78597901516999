import Cookies from 'js-cookie';
import moment from 'moment';

export async function cookieSetter(key, value) {
    const expiryTime = moment().add(24, 'hours').toISOString();
    Cookies.set(key, value);
    Cookies.set('ExpiryTime', expiryTime);
}

export function cookieGetter(key) {
    const cookie = Cookies.get(key);
    const expiryTime = Cookies.get('ExpiryTime');

    if (!expiryTime || moment().isAfter(moment(expiryTime))) {
        Cookies.remove(key);
        Cookies.remove('ExpiryTime');
        return null;
    }
    return cookie;
}


export function findInvalidEmail(emails) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (const email of emails) {
        if (!emailRegex.test(email)) {
            return {
                "isValid": false,
                email: email
            };
        }
    }

    return {
        "isValid": true,
        email: null
    };
}