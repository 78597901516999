import React from "react";
import { useConfigureContext } from "../Context/ConfigureContext"
import Header from "../template/Header";
import Footer from "../template/Footer";
import TitleTab from "./title-tab";
import FilterGroup from "./filter-group";
import FilterBar from "./filter-bar";
import AppOptionGroupComponent from "./app-option-group";
import Monitoring from "./monitoring-tab";
import CategoryController from "./category-controller";
import { ToastContainer } from "react-toastify";
import useToast from "../Hooks/Toast"
import CircularProgress from '@mui/material/CircularProgress';
import EmailForm from './EmailNotificationForm'
import ShapeSelector from './alertLevel'

const Category = () => {
  const notify = useToast();
  const {
    startDate,
    endDate,
    emailEnable,
    title,
    dateSelected,
    preFilledData,
    selected,
    selectedOptions,
    operator,
    filterGroups,
    editConfigation,
    excludeValue,
    matchCase,
    selectedDimension,
    selectedDimensionOperator,
    dimensionValue,
    finalSaveEnable,
    getEmail,
    alertLevel,
  } = useConfigureContext();

  const {
    entityTypeData,
    saveMonitoringConfig,
    loadingList,
  } = CategoryController(selected?.key);

  // Create an object to store dimension filter data
  const handleSaveChanges = async (id) => {
    const dimensionFilters = filterGroups
      ?.filter(
        (filterGroup) =>
          filterGroup.type === "dimension"
      )
      .map((filterGroup) => {
        return {
          dimensionName: selectedDimension?.[filterGroup.id]?.toLowerCase(),
          not: excludeValue?.[filterGroup.id] ?? false,
          operator: selectedDimensionOperator[filterGroup.id],
          expressions: [dimensionValue?.[filterGroup.id]],
          caseSensitive: matchCase?.[filterGroup.id] ?? false,
        };
      });

    // Now, dimensionFilters contains the desired structure.

    const metricFilters = filterGroups
      ?.filter(
        (filterGroup) =>
          filterGroup.type === "metric"
      )
      .map((filterGroup) => {
        return {
          metricName: selectedDimension?.[filterGroup.id]?.toLowerCase(),
          operator: selectedDimensionOperator?.[filterGroup.id],
          comparisonValue: dimensionValue?.[filterGroup.id],
          not: excludeValue?.[filterGroup.id] ?? false,
        };
      });

    const entityIds = selectedOptions?.map((e) => e.id);

    const CurrMonitoringConfig = {
      "id": id ? id : "",
      title: title,
      entityType: selected?.key,
      entityIds: entityIds,
      dimensionFilterGroups:
        dimensionFilters?.length > 0
          ? [
            {
              operator: operator.toUpperCase(),
              filters: dimensionFilters,
            },
          ]
          : [],
      metricFilterGroups:
        metricFilters?.length > 0
          ? [
            {
              operator: operator.toUpperCase(),
              filters: metricFilters,
            },
          ]
          : [],
      runMonitoringFor: dateSelected,
      startDate: startDate,
      endDate: endDate,
      getEmailOn:getEmail,
      alertLevel:alertLevel,
      createdOn: new Date().toISOString(),
      isActive: true,
      lastRunTs: new Date().toISOString(),
    };

    // to use toast to show the process...
    if (!CurrMonitoringConfig?.title) {
      notify(`Title cann't be empty`, 'error');
    }
    else if (!entityIds?.length) {
      notify(`Entity cann't be empty`, 'error');
    }
    else if (metricFilters?.length === 0) {
      notify(`Metric cann't be empty`, 'error');
    }
    else {
      await saveMonitoringConfig(CurrMonitoringConfig);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="body bg-color">
        <div className="container-small">
          <TitleTab />
          <ShapeSelector/>
          <Monitoring
            entityTypeData={entityTypeData}
            loadingList={loadingList}
          />
          <FilterGroup />
          <FilterBar />
          <AppOptionGroupComponent />
          {emailEnable && <EmailForm />}
          {finalSaveEnable &&
            <div className="frequently">
              {preFilledData || editConfigation ? (
                <button
                  className="button frequently-btn"
                  onClick={() => handleSaveChanges(editConfigation?.[0]?.id || preFilledData?.[0]?.id)}
                  disabled={loadingList}
                >
                  {loadingList ? <CircularProgress color='inherit'/> : 'Save Updated Config'}
                </button>
              ) : (
                <button
                  className="button frequently-btn"
                  onClick={() => handleSaveChanges()}
                  disabled={loadingList}
                >
                  {loadingList ? <CircularProgress color='inherit'/> : 'Save Config'}
                </button>
              )}
            </div>}
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default Category;
