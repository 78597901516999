import React, { useEffect, useState } from 'react';
import { Box, Modal, TextField, Button, Typography, CircularProgress } from '@mui/material';
import useSubmitForm from './useBudgetAndExpense';
import cancelHover from '../../assets/icons/delete (6) 1.png'
import cancel from '../../assets/icons/delete (7) 1.png'
import { formatCurrency } from '../utils/currencyFormatter';


const AddBudgetModal = ({ openBudgetModal, handleClose, Budget }) => {
  const { apiCall } = useSubmitForm(handleClose);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    if (openBudgetModal?.data) {
      const curBudget = parseFloat(openBudgetModal?.data?.monthlyBudget.replace(/,/g, '')) || 0;
      const userAmount = parseFloat(formValues.userAmount?.replace(/,/g, '') || '0');
      const totalBudget = curBudget + userAmount;

      setFormValues({
        advertiserName: openBudgetModal?.data?.advertiserName,
        userBudget: formatCurrency(curBudget) ,
        userAmount: '0', // Default userAmount is 0
        totalBudget: formatCurrency(totalBudget),
      });
    }
  }, [openBudgetModal]);
  const handleChange = (e) => {
    const { name, value } = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDescription = (e) => {
    const description = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      description: description,
    }));
  };

  const handleAmount = (e) => {
    let input = e.target.value;

    // Remove all non-digit and non-period characters
    input = input.replace(/[^\d.]/g, '');

    // Ensure that there's only one period in the input
    const parts = input.split('.');
    if (parts.length > 2) {
      input = `${parts[0]}.${parts.slice(1).join('')}`;
    }

    const userAmount = Number(input);
    const totalBudget = Number(formValues.userBudget.replace(/,/g, '')) + userAmount;

    setFormValues((prev) => ({
      ...prev,
      userAmount: formatCurrency(userAmount),
      totalBudget: formatCurrency(totalBudget),
    }));
  };

  const handleKeyDown = (e) => {
    // Allow only digits, period, and control keys
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab',
      'Control', 'v', 'V', 'c', 'C', 'a', 'A', '.'
    ];

    const isCtrlKey = e.ctrlKey || e.metaKey; // For Mac users using Command key

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlKey) {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const budgetData = {
      type: "budget",
      advertiserName: formValues.advertiserName,
      advertiserId: openBudgetModal?.data?.advertiserId,
      value: parseFloat(formValues.userAmount.replace(/,/g, '')),
      description: formValues.description,
    };
    apiCall(budgetData, openBudgetModal?.data?.id)
      .then(() => {
        setLoading(false);
        window.location.reload(); // Reload the whole page
      });
  };


  return (
    <Modal open={openBudgetModal?.open} onClose={() => handleClose()}>
      <Box className='border' sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        px: 24,
        py: 8,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        opacity: 0.90,
      }}>
        <Box
          sx={{
            position: 'absolute',
            top: 90,
            right: 226,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <img
            src={cancel}
            alt="cancel"
            // className="h-10"
            onMouseOver={(e) => e.currentTarget.src = cancelHover}
            onMouseOut={(e) => e.currentTarget.src = cancel}
            onClick={handleClose}
          />
        </Box>
        <Box sx={{
          border: "1px solid #1C4645",
          p: 10,
          borderRadius: 10
        }}
        >

          <form onSubmit={handleSubmit} sx={{ border: '1px solid #ccc' }}>

            {/* First row */}

            <Box sx={{ border: '1px solid #ccc', px: 4, py: 4 }}>
              <TextField
                id="advertiser-name"
                name="Advertiser Name"
                disabled
                label="Advertiser Name"
                value={formValues.advertiserName}
                fullWidth
                inputProps={{ style: { fontSize: 16 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 16 } }}
              />
            </Box>

            {/* Second row */}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 4, py: 4, border: '1px solid #ccc' }}>
              <TextField
                id="current-budget"
                label="Current Budget"
                name="Current Budget"
                disabled
                value={formValues.userBudget}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="add-new-amount"
                label="Add New Amount"
                name="new amount"
                value={formValues.userAmount || ''}
                onChange={(e) => handleAmount(e)}
                onKeyDown={handleKeyDown}
                onPaste={(e) => {
                  const paste = (e.clipboardData || window.clipboardData).getData('text');
                  if (!/^\d*\.?\d*$/.test(paste)) {
                    e.preventDefault();
                  }
                }}
                variant="outlined"
                fullWidth
              />
              <TextField
                id="total-budget"
                label="Total Budget"
                name="total budget"
                // disabled
                value={formValues.totalBudget}
                fullWidth
              />
            </Box>

            {/* Third row */}

            <Box sx={{ border: '1px solid #ccc', px: 4, py: 4 }}>
              <TextField
                id="description"
                name="Description"
                variant="outlined"
                label="Description"
                required
                onChange={(e) => handleDescription(e)}
                fullWidth
                inputProps={{ style: { fontSize: 16 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 16 } }}
              />
            </Box>

            {/* fourth row */}

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 8 }}>
              <Button
                variant="outlined"
                sx={{
                  borderColor: '#1C4645',
                  color: '#1C4645',
                  '&:hover': {
                    backgroundColor: '#1C4645',
                    color: 'white',
                    borderColor: '#1C4645',
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  backgroundColor: '#1C4645',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'white',
                    borderColor: '#1C4645',
                    color: '#1C4645',
                  },
                  ml: 2,
                }}
                // onClick={() =>handleSubmit()}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" sx={{ color: 'white' }} /> : 'Save'}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddBudgetModal;