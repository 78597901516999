import { useState } from 'react';
import { toast } from 'react-toastify';

const useToast = () => {
    const [toastId, setToastId] = useState(null);

    const notify = (message, type = 'info') => {
        if (toastId) {
            // If a toast is already displayed, close it before showing the new one
            toast.dismiss(toastId);
        }

        const newToastId = toast[type](message);
        setToastId(newToastId);
    };

    return notify;
};

export default useToast;
