import React, { createContext, useContext, useEffect, useState } from "react";
import { cookieGetter } from '../utils/HelperFunction'
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [islogedIn, setLogedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    toSetLogin();
    setLoading(false)
  }, [])

  const toSetLogin = () => {
    const appIdToken = cookieGetter("appIdToken")
    appIdToken ? setLogedIn(true) : setLogedIn(false);
  }
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        editData,
        setEditData,
        isModalOpen,
        openModal,
        closeModal,
        islogedIn,
        toSetLogin,
        loading
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
