import React, { useEffect, useState } from "react";
import Delete from "../../assets/icons/delete.svg";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const UserData = ({
  selectedData,
  editData,
  setSelected,
  entityTypeData,
  saveEnabled,
  setEditData,
  setSelectedData,
  setSelectedOptions
}) => {
  const [edit, setEdit] = useState([]);
  useEffect(() => {
    if (editData[0]?.advertiserIds.length) {
      setSelected({
        key: "ADVERTISERS",
        label: "Advertisers",
      });
      if (entityTypeData) {
        const advertiserIds = editData[0].advertiserIds;
        const selectedOptions = entityTypeData.entities.filter((entity) =>
          advertiserIds.includes(entity.id)
        );
        setEdit([{ savedFilterOption: "Advertisers", selectedOptions }]);
      }
    } else if (editData[0]?.partnerIds.length) {
      setSelected({
        key: "PARTNERS",
        label: "Partners",
      });
      if (entityTypeData) {
        const partnerIds = editData[0].partnerIds;
        const selectedOptions = entityTypeData.entities.filter((entity) =>
          partnerIds.includes(entity.id)
        );
        setEdit([{ savedFilterOption: "Partners", selectedOptions }]);
      }
    }
    if (editData) {
      saveEnabled(true);
    }
  }, [editData, entityTypeData, setSelected]);

  const deleteData = (idx) => {
    if (selectedData?.length) {
      const filterData = selectedData.filter((item, index) => {
        return index !== idx
      })
      if (!filterData?.length) {
        saveEnabled(false);
      }
      setSelectedData(filterData)
    }
    else {
      setEdit([]);
      const updatedEditData = [...editData];
      updatedEditData.forEach((userData) => {
        userData.advertiserIds = [];
        userData.partnerIds = [];
      });

      setSelectedOptions([])
      setEditData(updatedEditData);
      saveEnabled(false);
    }
  };

  return (
    <div className="table">
      <>
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: '#afb3af' }}>
                <TableRow sx={{ border: 1, borderColor: '#b5b5b5' }}>
                  <TableCell sx={{ width: '15%', margin: 'auto', border: 'none' }}>Type</TableCell>
                  <TableCell align="center" sx={{ width: '30%', margin: 'auto', border: 'none' }}>Id</TableCell>
                  <TableCell align="left" sx={{ width: '40%', margin: 'auto', border: 'none', display: 'flex', justifyContent: 'center' }}>Name</TableCell>
                  <TableCell align="center" sx={{ width: '25%', margin: 'auto', border: 'none' }}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedData?.length > 0
                  ? selectedData?.map((data) =>
                    data?.map((data, index) => (
                      <>
                        <TableRow className="flex align-center list-non" key={index}>
                          <TableCell>
                            <p>{data?.savedFilterOption}</p>
                          </TableCell>
                          <TableCell>
                            {data?.selectedOptions?.map(
                              (monitoringConfig, index) => (
                                <>
                                  <div className="flex tbl">
                                    <li className="">
                                      {monitoringConfig?.id}
                                    </li>
                                  </div>
                                </>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {data?.selectedOptions?.map(
                              (monitoringConfig, index) => (
                                <>
                                  <div className="flex tblname">
                                    <li className="">
                                      {monitoringConfig?.name}
                                    </li>
                                  </div>
                                </>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            <ul className="flex justify-center list-none">
                              {/* <li className="alert-body-icon non">
                                <img src={Edit} alt="" />
                              </li> */}
                              <li className="alert-body-icon">
                                <img src={Delete} alt="" onClick={() => deleteData(index)} />
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  )
                  : edit?.map((data, index) => (
                    <>
                      <TableRow className="flex align-center list-none" key={index}>
                        <TableCell>
                          <p>{data?.savedFilterOption}</p>
                        </TableCell>
                        <TableCell>
                          {data?.selectedOptions?.map(
                            (monitoringConfig, index) => (
                              <>
                                <div className="flex tbl">
                                  <li className="">
                                    {monitoringConfig?.id}
                                  </li>
                                </div>
                              </>
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {data?.selectedOptions?.map(
                            (monitoringConfig, index) => (
                              <>
                                <div className="tblname">
                                  <li className="">
                                    {monitoringConfig?.name}
                                  </li>
                                </div>
                              </>
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <ul className="flex justify-center list-none ">
                            {/* <li className="alert-body-icon">
                              <img src={Edit} alt="" />
                            </li> */}
                            <li className="alert-body-icon">
                              <img src={Delete} alt="" onClick={() => deleteData(index)} />
                            </li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}

              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    </div>
  );
};

export default UserData;

// import React, { useEffect, useState } from "react";
// import Delete from "../../assets/icons/delete.svg";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// const UserData = ({
//   selectedData,
//   editData,
//   setSelected,
//   entityTypeData,
//   saveEnabled,
//   setEditData,
//   setSelectedData,
//   setSelectedOptions
// }) => {
//   const [edit, setEdit] = useState([]);
//   useEffect(() => {
//     if (editData[0]?.advertiserIds.length) {
//       setSelected({
//         key: "ADVERTISERS",
//         label: "Advertisers",
//       });
//       if (entityTypeData) {
//         const advertiserIds = editData[0].advertiserIds;
//         const selectedOptions = entityTypeData.entities.filter((entity) =>
//           advertiserIds.includes(entity.id)
//         );
//         setEdit([{ savedFilterOption: "Advertisers", selectedOptions }]);
//       }
//     } else if (editData[0]?.partnerIds.length) {
//       setSelected({
//         key: "PARTNERS",
//         label: "Partners",
//       });
//       if (entityTypeData) {
//         const partnerIds = editData[0].partnerIds;
//         const selectedOptions = entityTypeData.entities.filter((entity) =>
//           partnerIds.includes(entity.id)
//         );
//         setEdit([{ savedFilterOption: "Partners", selectedOptions }]);
//       }
//     }
//     if (editData) {
//       console.log("editData:", editData);
//       saveEnabled(true);
//     }
//   }, [editData, entityTypeData, setSelected]);

//   const deleteData = (idx) => {
//     if (selectedData?.length) {
//       const filterData = selectedData.filter((item, index) => index !== idx);
//       if (!filterData?.length){
//         saveEnabled(false);
//       }
//       setSelectedData(filterData);
//     } else {
//       setEdit([]);
//       const updatedEditData = [...editData];
//       updatedEditData.forEach((userData) => {
//         userData.advertiserIds = [];
//         userData.partnerIds = [];
//       });

//       setSelectedOptions([]);
//       setEditData(updatedEditData);
//       saveEnabled(false);
//     }
//   };

//   return (
//     <div className="table">
//       <>
//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} aria-label="simple table">
//             <TableHead sx={{ backgroundColor: '#afb3af' }}>
//               <TableRow sx={{ border: 1, borderColor: '#b5b5b5' }}>
//                 <TableCell sx={{ width: '15%', margin: 'auto', border: 'none' }}>Type</TableCell>
//                 <TableCell align="center" sx={{ width: '30%', margin: 'auto', border: 'none' }}>Id</TableCell>
//                 <TableCell align="left" sx={{ width: '40%', margin: 'auto', border: 'none', display: 'flex', justifyContent: 'center' }}>Name</TableCell>
//                 <TableCell align="center" sx={{ width: '25%', margin: 'auto', border: 'none' }}>Delete</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedData?.length > 0
//                 ? selectedData?.map((data, index) => (
//                   <TableRow className="flex align-center list-non" key={index}>
//                     <TableCell>
//                       <p>{data?.savedFilterOption}</p>
//                     </TableCell>
//                     <TableCell>
//                       {data?.selectedOptions?.map((monitoringConfig, idx) => (
//                         <div className="flex tbl" key={idx}>
//                           <li className="">{monitoringConfig?.id}</li>
//                         </div>
//                       ))}
//                     </TableCell>
//                     <TableCell>
//                       {data?.selectedOptions?.map((monitoringConfig, idx) => (
//                         <div className="flex tblname" key={idx}>
//                           <li className="">{monitoringConfig?.name}</li>
//                         </div>
//                       ))}
//                     </TableCell>
//                     <TableCell>
//                       <ul className="flex justify-center list-none">
//                         <li className="alert-body-icon">
//                           <img src={Delete} alt="delete" onClick={() => deleteData(index)} />
//                         </li>
//                       </ul>
//                     </TableCell>
//                   </TableRow>
//                 ))
//                 : edit?.map((data, index) => (
//                   <TableRow className="flex align-center list-none" key={index}>
//                     <TableCell>
//                       <p>{data?.savedFilterOption}</p>
//                     </TableCell>
//                     <TableCell>
//                       {data?.selectedOptions?.map((monitoringConfig, idx) => (
//                         <div className="flex tbl" key={idx}>
//                           <li className="">{monitoringConfig?.id}</li>
//                         </div>
//                       ))}
//                     </TableCell>
//                     <TableCell>
//                       {data?.selectedOptions?.map((monitoringConfig, idx) => (
//                         <div className="tblname" key={idx}>
//                           <li className="">{monitoringConfig?.name}</li>
//                         </div>
//                       ))}
//                     </TableCell>
//                     <TableCell>
//                       <ul className="flex justify-center list-none">
//                         <li className="alert-body-icon">
//                           <img src={Delete} alt="delete" onClick={() => deleteData(index)} />
//                         </li>
//                       </ul>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </>
//     </div>
//   );
// };

// export default UserData;