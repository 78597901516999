import { useNavigate } from "react-router-dom";
import MonitoringConfigs from "./home-controller";
// import { useConfigureContext } from "../Context/ConfigureContext"
import { useConfigureContext } from "../Context/ConfigureContext";
import Header from "../template/Header";
import Footer from "../template/Footer";
import Close from "../../assets/icons/close.svg";
import { useDataContext } from "../Context/context";
import UserController from "../User-Management/user-controller";
import useToast from "../Hooks/Toast"
import Config from "../template/Configure"
import { ToastContainer } from "react-toastify";

const Home = () => {
  const { setData, setEditData } = useDataContext();
  const navigate = useNavigate();
  const notify = useToast();
  const { userData } = UserController();
  // const { configureAlertBtn, setConfigureAlertBtn } = useState(true);
  const {
    monitoringConfigs,
    loading,
    handleSearch,
    filteredData,
    searchInput,
    setSearchInput,
    isActive,
  } = MonitoringConfigs();


  const {
    setEditConfiguration,
    setPreFilledData
  } = useConfigureContext();


  async function handleRedirect() {
    if (userData?.role === "VIEWER") {
      notify(`You don't have permission to access this page`, 'success');
    }
    await setData([]);
    await setEditData([]);
    await setEditConfiguration([]);
    await setPreFilledData([]);
    navigate("/category");
    window.location.reload();
  }
  return (
    <>
      <div className="bg-color">
        <Header />
        <ToastContainer />
        <div className="body">
          <div className="container-small">
            <div className="summary bg-white shadow mb-35">
              <h2>Summary</h2>
              <div className="alert-box flex justify-space">
                <div className="alert-fired">
                  <p>Active Config</p>
                  <p>{isActive}</p>
                </div>
                <div className="alert-configured">
                  <p>Alerts Configured</p>
                  <p>{monitoringConfigs?.length}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-space items-center">
              <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white user" onClick={handleRedirect}>
                +&nbsp;&nbsp;Configure Alert
              </button>
              <form className="search-box" style={{ position: 'relative' }}>
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search Alert By Config Name..."
                  value={searchInput}
                  onChange={handleSearch}
                />
                {searchInput && (
                  <img
                    src={Close}
                    className="search-close"
                    alt=""
                    onClick={() => setSearchInput('')}
                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                  />
                )}
              </form>
            </div>
            {loading ? (
              <div className="alert-table bg-white shadow mt-35 overlay">
                <div className="loading-Overlay"></div>
              </div>
            ) : (
              <Config
                filteredData={filteredData}
                searchInput={searchInput}
                monitoringConfigs={monitoringConfigs}
                isActive={isActive}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;