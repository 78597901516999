/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Menu from "../../assets/icons/menu.svg";
import Madkpi from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import UserController from "../User-Management/user-controller";
import { ToastContainer, toast } from "react-toastify";
import { useDataContext } from "../Context/context"
import useToast from "../Hooks/Toast"
import Cookies from "js-cookie";
import money from '../../assets/icons/money (1) 1.png'
import settings from '../../assets/icons/settings (2) 1.png'
import group from '../../assets/icons/group 1.png'
import logoutImg from '../../assets/icons/Logout_Icon.png'



const Header = () => {
  const { toSetLogin } = useDataContext();
  const [toggle, setToggle] = useState(false);
  const Navigate = useNavigate();
  const { userData } = UserController();
  const [userAccess, setUserAccess] = useState(false);
  const [admin, setAdmin] = useState(false);
  const notify = useToast();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const visible = prevScrollPos > currentScrollPos;
    setPrevScrollPos(currentScrollPos);
    setVisible(visible);
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  if (userData?.role === "EDITOR") {
    setUserAccess(true);
    notify(`You don't have permission to access this page`, 'success');
  } else if (userData?.role === "ADMIN") {
    setAdmin(true);
  }

  const logout = () => {
    Cookies.remove('appIdToken')
    toSetLogin();
    Navigate("/login");
  };

  return (
    <>
      {<div className={visible ? 'header' : 'hide'}>
        <h1 className="m-0">
          <a href="/home">
            <img className="max-w-[141px] ml-6 h-[70%]" src={Madkpi} alt="" />
          </a>
        </h1>
        <div className="max-lg:hidden lg:flex lg:gap-5 xl:gap-12 w-[85%]  justify-end items-center lg:mr-2 xl:mr-5">
          <button onClick={() => Navigate("/budget")} className="flex items-center text-primaryColor text-lg font-medium">
            <img src={money} alt="Budget Icon" className="mr-1 xl:mr-2" />
            Budget - Management
          </button>
          <button onClick={() => Navigate("/home")} className="flex items-center text-primaryColor text-lg font-medium">
            <span><img src={settings} alt="Configurations Icon" className="mr-1 xl:mr-2" /></span>
            Configurations
          </button>
          <button onClick={() => Navigate("/user")} className="flex items-center text-primaryColor text-lg font-medium">
            <span className=""><img src={group} alt="User Management Icon" className="mr-1 xl:mr-2" /></span>
            User - Management
          </button>
          <button onClick={() => logout()} className="flex items-center px-3 py-1.5 text-lg font-medium rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white user">
            <img src={logoutImg} alt="Budget Icon" className="mr-2" />
            Logout
          </button>
        </div>

        <div className="mobilemenu">
          <a href="#" onClick={() => setToggle(!toggle)}>
            <img className="menu" src={Menu} alt="" />
          </a>
        </div>
      </div>}
      <ToastContainer />
      {toggle && (
        <div className="sidebar w-[30dvw]">
          <div className="flex justify-space">
            <div className="flex flex-col justify-start items-start gap-4">
              <button onClick={() => Navigate("/budget")} className="text-primaryColor flex items-center justify-center text-lg  font-medium">
                <img src={money} alt="Budget Icon" className="mr-2" />
                Budget - Management
              </button>

              <button onClick={() => Navigate("/home")} className="text-primaryColor flex items-center justify-center text-lg  font-medium">
                <span><img src={settings} alt="Configurations Icon" className="mr-2" /></span>
                Configurations</button>

              {/* <button onClick={() => Navigate("/category")}>Create Category</button> */}

              <button onClick={() => Navigate("/user")} className="text-primaryColor flex items-center justify-center text-lg  font-medium">
                <span className=""><img src={group} alt="User Management Icon" className="mr-2" /></span>
                User - Management</button>
            </div>
            <button onClick={() => logout()} className="font-normal px-5 py-2 rounded-[7px] text-white flex justify-center items-center bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white user">
              <img src={logoutImg} alt="Budget Icon" className="mr-2" />
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
