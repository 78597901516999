import { useState, useEffect } from 'react';
import axios from 'axios';
import useToast from "../Hooks/Toast";
import { cookieGetter } from '../utils/HelperFunction'

const useGetBudgetDetail = () => {
    const appIdToken = cookieGetter("appIdToken");
    const notify = useToast();
    const [data, setData] = useState([]);
    const [hovered, setHovered] = useState(new Array(data.length).fill(false)); // Initialize as an array of false values
    const [loading, setLoading] = useState(true);

    const getBudgetDetail = async () => {
        try {
            const response = await axios.get(
                "https://monitoring-webapp-iukyyubnkq-uc.a.run.app/budget",
                {
                    headers: { Authorization: `Bearer ${appIdToken}` },
                }
            );
            const fetchedData = response?.data?.budgets || [];
            setData(fetchedData);
            setHovered(new Array(fetchedData.length).fill(false));
            setLoading(false);
            // console.log('Fetched data:', fetchedData);
        } catch (error) {
            notify(`Error happened in fetching data`, 'error');
            console.error("Error listing monitoring configs:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getBudgetDetail();
    }, []);

    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState(null);

    const handleSearch = (e) => {
        const inputValue = e.target.value;
        setSearchInput(inputValue);
        const filtered = data?.filter((item) =>
            item.advertiserName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setFilteredData(filtered);
    };
    return {
        data, hovered, loading, setHovered, handleSearch, searchInput,
        setSearchInput,
    };
};

export default useGetBudgetDetail;
