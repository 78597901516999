import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDataContext } from "./components/Context/context";
import "./App.css";
import "./assets/style/app.scss";
import { Login } from "./components/login-page";
import { Home } from "./components/home";
import { Category } from "./components/category";
import { UserManagement } from "./components/User-Management";
import NewUserManagement from './components/User-Management/NewUserManagement';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Budget from "./components/budget/Budget";
function App() {
  const { islogedIn,loading } = useDataContext();


  if(loading){
    return <></>
  }
  
  return (
    <BrowserRouter>
        <Routes>
          {!islogedIn ?
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
            :
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/category" element={<Category />} />
              <Route path="/budget" element={<Budget />} />
              <Route path="/user" element={<UserManagement />} />
              <Route path="/new-user" element={<NewUserManagement />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </>
          }
        </Routes>
    </BrowserRouter>
  );
}

export default App;
