import axios from 'axios';
import useToast from "../Hooks/Toast";
import { cookieGetter } from '../utils/HelperFunction';
import { useState } from 'react';

const useAdvertiser = (handleClose) => {
    const [loading, setLoading] = useState(false);
    const appIdToken = cookieGetter("appIdToken");
    const notify = useToast();

    const advertiserApiCall = async (finalObject) => {
        const url = finalObject?.id
            ? `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/budget/${finalObject?.id}`
            : 'https://monitoring-webapp-iukyyubnkq-uc.a.run.app/budget';

        const method = finalObject?.id ? 'put' : 'post';

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${appIdToken}`,
                },
                data: finalObject,
            });

            if (method === 'post') {
                if (response.status !== 200) {
                    notify('There is some issue please try again', 'error');
                } else {
                    notify('Advertiser is successfully added', 'success');
                    handleClose();
                    window.location.reload();
                }
            } else if (method === 'put') {
                if (response.status !== 200) {
                    notify('There is some issue with updating the advertiser, please try again', 'error');
                } else {
                    notify('Advertiser is successfully updated', 'success');
                    handleClose();
                    window.location.reload();
                }
            }
        } catch (error) {
            if (method === 'post') {
                notify('There is some issue with adding the advertiser, please try again', 'error');
            } else if (method === 'put') {
                notify('There is some issue with updating the advertiser, please try again', 'error');
            }
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return { advertiserApiCall, loading };
};

export default useAdvertiser;