import React, { useEffect, useState } from "react";
import { role, adminRole, userAcessDropDown } from "../utils/constants";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { cookieGetter } from '../utils/HelperFunction.js';

const Form = ({
  email,
  setEmail,
  name,
  setName,
  selected,
  setSelected,
  selectedRole,
  setSelectedRole,
  editData,
}) => {
  const userRole = cookieGetter('role')
  const [isActive, setIsActive] = useState(false);
  const [isRoleActive, setIsRoleActive] = useState(false);

  useEffect(() => {
    if (editData?.length) {
      setName(editData[0].name);
      setEmail(editData[0].email);
      setSelected(
        userAcessDropDown.find((item) => item.key === editData[0].accessType) ||
        userAcessDropDown[0]
      );
      setSelectedRole(
        role.find((item) => item.key === editData[0].role) || role[0]
      );
    } else {
      setName("");
      setEmail("");
      setSelected(userAcessDropDown[0]);
      setSelectedRole(role[0]);
    }
  }, [editData]);

  const handleChange = (event) => {
    setSelected(event.target.value);
    setIsActive(!isActive);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setIsRoleActive(!isRoleActive);
  };

  return (
    <form>
      <div className="innerform">
        <div className="px-5 py-6 w-[50%]">
          <TextField
            type="outlined"
            label="UserName"
            value={name}
            className="input-1"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="px-5 py-6 w-[50%]">
          <TextField
            type="email"
            label="UserEmail"
            value={email}
            className="input-1"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="innerform">
        <div className="px-5 py-6 w-[50%] type">
          <InputLabel>Type</InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              value={selected}
              onChange={handleChange}
              onClick={() => {
                setIsActive(!isActive);
                setIsRoleActive(false);
              }}
              renderValue={(selected) => selected?.label || "Type"}
            >
              {userAcessDropDown.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  className="item cursor-pointer hover:text-primaryColor"
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="px-5 py-6 w-[50%]">
          <InputLabel>Role</InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              onClick={() => {
                setIsRoleActive(!isRoleActive);
                setIsActive(false);
              }}
              renderValue={(selectedRole) => selectedRole?.label || "Role"}
            >
              {(userRole === "ADMIN" ? adminRole : role)?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  className="item cursor-pointer hover:text-primaryColor"
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </form>
  );
};

export default Form;