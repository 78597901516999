import React, { createContext, useContext, useEffect, useState } from "react";
import { useDataContext } from "./context"
import moment from "moment";

const ConfigureContext = createContext();

export const ConfigDataProvider = ({ children }) => {
    const [alertLevel, setAlertLevel] = useState('GENERAL');
    const [finalSaveEnable, setFinalSaveEnable] = useState(false)
    const [getEmail, setGetEmail] = useState([]);
    const [showFilterGroup, setShowFilterGroup] = useState(false);
    const [selectedDimensionOperator, setSelectedDimensionOperator] =
        useState("EQUALS");
    const [selectedDimension, setSelectedDimension] = useState({});
    const [selectedMetric, setSelectedMetric] = useState({
        key: "select_metric",
        label: "Select Metric",
    });

    const [selectedMetricOperator, setSelectedMetricOperator] =
        useState("EQUALS");
    const [metricFilterValue, setMetricFilterValue] = useState();

    const [filterType, setFilterType] = useState("");
    const [showFilterTypeDropdown, setShowFilterTypeDropdown] = useState(false);
    const [dimensionFilterGroup, setDimensionFilterGroup] = useState(false);
    const [excludeValue, setExcludeValue] = useState({});
    const [matchCase, setMatchCase] = useState({});
    const [AppOptionGroup, setAppOptionGroup] = useState(false);
    const [dimesionFilterValue, setDimesionFilterValue] = useState();
    const [filterGroups, setFilterGroups] = useState([]); // New state for filter groups4
    const { data, editData } = useDataContext();
    const [preFilledData, setPreFilledData] = useState(data);
    const [editConfigation, setEditConfiguration] = useState(editData);
    const [emailEnable, setEmailEnable] = useState(false);
    const [title, setTitle] = useState('');
    const [dimensionValue, setDimensionValue] = useState({});
    const [operator, setOperator] = useState("and");
    const [selected, setSelected] = useState({
        key: "ADVERTISERS",
        label: "Advertisers",
    });
    const [dateSelected, setDateSelected] = useState("CURRENT_DAY");
    const [pickDate, setPickDate] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            key: "selection",
        },
    ]);

    useEffect(() => {
        setPreFilledData(data);
        setEditConfiguration(editData);

        // to set the data group....
        const tmpArray = [];
        if (data?.length) {
            if (data?.[0]?.dimensionFilterGroups?.length) {
                data?.[0]?.dimensionFilterGroups?.[0]?.filters?.forEach((data, index) => {
                    tmpArray.push({ type: "dimension", id: `dimension-${index + 1}`, delete: false, data })
                })
            }
            else {
                data?.[0]?.metricFilterGroups?.[0]?.filters?.forEach((data, index) => {
                    tmpArray.push({ type: "metric", id: `metric-${index + 1}`, delete: false, data })
                })
            }
            setFilterType("dimension");
        }
        if (editData?.length) {
            if (editData?.[0]?.dimensionFilterGroups?.length) {
                editData?.[0]?.dimensionFilterGroups?.[0]?.filters?.forEach((data, index) => {
                    tmpArray.push({ type: "dimension", id: `dimension-${index + 1}`, delete: false, data })
                })
            }
            else {
                editData?.[0]?.metricFilterGroups?.[0]?.filters?.forEach((data, index) => {
                    tmpArray.push({ type: "metric", id: `metric-${index + 1}`, delete: false, data })
                })
            }
            setFilterType("metric");
        }

        setFilterGroups(tmpArray);
        setShowFilterGroup(true);
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'))
    }, [data, editData]);

    // to set the operator if data is copy or edit
    useEffect(() => {
        if (preFilledData?.length) {
            const operator = preFilledData?.[0]?.dimensionFilterGroups?.[0]?.operator;
            setOperator(operator === "AND" ? "and" : "or")
        }
        if (editConfigation?.length) {
            const operator = editConfigation?.[0]?.dimensionFilterGroups?.[0]?.operator;
            setOperator(operator === "AND" ? "and" : "or")
        }
    }, [preFilledData, editConfigation])


    useEffect(() => {
        if (editConfigation?.[0]?.id) {
            setTitle(editConfigation?.[0].title);
            setDateSelected(editConfigation?.[0]?.runMonitoringFor)
            setGetEmail(editConfigation?.[0]?.getEmailOn)
            setAlertLevel(editConfigation?.[0]?.alertLevel)
        }
        if (preFilledData[0]?.id) {
            setTitle(preFilledData[0].title);
            setDateSelected(preFilledData?.[0]?.runMonitoringFor)
            setGetEmail(preFilledData?.[0]?.getEmailOn)
            setAlertLevel(preFilledData?.[0]?.alertLevel)
        }
    }, [preFilledData, editConfigation,data,editData])

    useEffect(() => {
        if (dateSelected === 'CURRENT_DAY') {
            setEndDate(moment().endOf("day").format('YYYY-MM-DD'));
            setStartDate(moment().format('YYYY-MM-DD'));
        }
        else if (dateSelected === 'CURRENT_MONTH') {
            setEndDate(moment().endOf("month").format('YYYY-MM-DD'));
            setStartDate(moment().format('YYYY-MM-DD'));
        }
    }, [dateSelected])

    const handleAddFilterGroupClick = (type, id) => {
        const newFilterGroup = { type, id, delete: false };
        setDimensionFilterGroup(true);
        setFilterGroups([...filterGroups, newFilterGroup]);

        setShowFilterGroup(true);
        setFilterType(type);
        setShowFilterTypeDropdown(false);
    };


    return (
        <ConfigureContext.Provider
            value={{
                startDate,
                endDate,
                setEndDate,
                setStartDate,
                emailEnable,
                selectedRange,
                setSelectedRange,
                title,
                setTitle,
                dateSelected,
                setDateSelected,
                pickDate,
                setPickDate,
                preFilledData,
                setShowFilterGroup,
                selected,
                setSelected,
                selectedOptions,
                setSelectedOptions,
                handleAddFilterGroupClick,
                showFilterGroup,
                showFilterTypeDropdown,
                setShowFilterTypeDropdown,
                operator,
                setOperator,
                filterGroups,
                dimensionFilterGroup,
                editConfigation,
                setFilterGroups,
                setAppOptionGroup,
                excludeValue,
                setExcludeValue,
                matchCase,
                setMatchCase,
                selectedMetric,
                setSelectedDimension,
                selectedDimension,
                setDimesionFilterValue,
                dimesionFilterValue,
                selectedDimensionOperator,
                setSelectedDimensionOperator,
                dimensionValue,
                setDimensionValue,
                setSelectedMetric,
                selectedMetricOperator,
                setSelectedMetricOperator,
                metricFilterValue,
                setMetricFilterValue,
                AppOptionGroup,
                setDimensionFilterGroup,
                setEmailEnable,
                getEmail,
                setGetEmail,
                finalSaveEnable,
                setFinalSaveEnable,
                alertLevel, 
                setAlertLevel,
                setEditConfiguration,
                setPreFilledData
            }}
        >
            {children}
        </ConfigureContext.Provider>
    );
};

export const useConfigureContext = () => useContext(ConfigureContext);
