import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../Context/context";
import { useEffect, useState } from "react";
import useToast from "../Hooks/Toast"
import { cookieGetter } from '../utils/HelperFunction'

const MonitoringConfigs = () => {
  const navigate = useNavigate();
  const notify = useToast();
  const appIdToken = cookieGetter("appIdToken");
  const [monitoringConfigs, setMonitoringConfigs] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const { setData, setEditData } = useDataContext();

  const getMonitoringConfigData = async () => {
    try {
      if (appIdToken?.length) {
        const response = await axios.get(
          "https://monitoring-webapp-iukyyubnkq-uc.a.run.app/monitoring-configs",
          {
            headers: { Authorization: `Bearer ${appIdToken}` },
          }
        );
        const data = response?.data;
        const formattedData = data?.configs;
        setMonitoringConfigs(formattedData);
        let activeCount = 0;
        formattedData?.forEach((item) => {
          if (item?.isActive) {
            activeCount++;
          }
        })
        setIsActive(activeCount)
        setLoading(false);
      }
    } catch (error) {
      notify(`Error happen in fetching data`, 'error');
      console.error("Error listing monitoring configs:", error);
      setLoading(false);
    }
  };

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    const filtered = monitoringConfigs?.filter((item) =>
      item.title.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleCopy = (copyData) => {
    const data = monitoringConfigs.filter(
      (monitoringConfig) => monitoringConfig?.id === copyData
    );
    setData(data);
    setEditData([]);
    navigate("/category");
  };

  const handleActivateDeactivate = async (data, action, reload = false) => {
    try {
      const response = await axios.patch(
        // `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/monitoring-configs/${data.id}`,
        `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/monitoring-configs/${data.id}:${action}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${appIdToken}`,
          },
        }
      );

      notify(`Config ${action}`, 'success');

      if (reload || response.ok) {
        navigate("/home")
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting monitoring config:", error);
    }
  };

  const handleDelete = async (data) => {
    try {
      const response = await axios.delete(
        `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/monitoring-configs/${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${appIdToken}`,
          },
        }
      );

      if (response.status === 204) {
        // setMonitoringConfigs((prevConfigs) =>
        //   prevConfigs.filter((config) => config.id !== data.id)
        // );
        navigate("/home")
        window.location.reload();
        notify(`Config deleted Permanently`, 'success');
      }

    } catch (error) {
      notify(`Error happen in deleting config`, 'error');
      setLoading(false);
      console.error("Error deleting monitoring config:", error);
    }
  };

  const handleEdit = (editData) => {
    const data = monitoringConfigs.filter(
      (monitoringConfig) => monitoringConfig?.id === editData
    );

    setEditData(data);
    setData([]);
    navigate("/category");
  };

  useEffect(() => {
    getMonitoringConfigData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ... Other hooks

  return {
    // fetchEntities,
    // currMonitoringConfig,
    loading,
    monitoringConfigs,
    handleSearch,
    filteredData,
    searchInput,
    setSearchInput,
    handleCopy,
    handleActivateDeactivate,
    handleDelete,
    handleEdit,
    isActive
    // saveMonitoringConfig
  };
};

export default MonitoringConfigs;
